import { ActivatedRoute } from '@angular/router';

export const environment: {
  production: boolean;
  apiUrl: string;
  interval: number;
  clientData: any;
  current: string;
  buildType: string;
  isDev: boolean;
  fontFamily: string;
  actualView: string;
  beta: boolean;
  startDate: Date;
  endDate: Date;
  homeId: string;
  textColor: string;
  currentRoute?: ActivatedRoute;
  flavour: string;
} = {
  production: true,
  apiUrl: 'https://backend.total-energies.hobeen.com',
  interval: 30000,
  clientData: undefined,
  current: 'Resumen',
  buildType: 'Build de Producción',
  isDev: false,
  actualView: 'local',
  beta: false,
  startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
  endDate: new Date(
    new Date().getFullYear(),
    new Date().getMonth() + 1,
    0,
    0,
    0,
    0,
    0
  ),
  homeId: '',
  fontFamily: '"Nunito", sans-serif',
  textColor: '#374649',
  currentRoute: undefined,
  flavour: 'total',
};
